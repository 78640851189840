body {
  margin: 0;
  padding: 0 0 0 0;
  font-family: sans-serif;
}

.navbar {
  margin-bottom: 30px;
}

.clickable {
  cursor: pointer;
}

#root{
  background-color: #6c757d;
}