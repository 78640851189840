.App {
  text-align: center;
}

/* ::selection {
  color: none;
  background: none;
} */

html{
  background-color:#6c757d
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

#root{
  width: 100%
}

.container{
  max-width: '100%'
}

.row{
  max-width: '100%'
}

/* This is a fix for the dialog that seems to like to add padding to the contents of the page? */
body, header{
  padding:0 !important;
  overflow-y: auto !important;
}


td.MuiTableCell-body{
  border-bottom:none
}

.MuiTableRow-root:hover { 
  border:1px solid #90caf9
}

td.MuiTableCell-footer{
  color:whitesmoke; 
  background-color:#1D2124;
  margin:auto
}

p.MuiTablePagination-displayedRows{
  margin:auto
}
p.MuiTablePagination-selectLabel{
  margin:auto
}

svg.MuiSvgIcon-root{
  color:#fef6f5;
}

#orangeToggles.MuiToggleButton-root{
  color:whitesmoke;
  border-color: #fef6f5;
  background: #222;
  text-transform: none;
}

#orangeToggles.MuiToggleButton-root.Mui-selected{
  color:black;
  font-weight:bold;
  border-color: #fef6f5;
  background:#ff5a3f;
  text-transform: none;
}

#orangeToggles.MuiToggleButton-root.Mui-selected:hover{
  color:black;
  font-weight:bold;
  border-color: #fef6f5;
  background:#ff5a3f;
  text-transform: none;
}

#greenToggles.MuiToggleButton-root{
  color:whitesmoke;
  border-color: #fef6f5;
  background: #222;
  text-transform: none;
}

#greenToggles.MuiToggleButton-root.Mui-selected{
  color:black;
  font-weight:bold;
  border-color: #fef6f5;
  background:#28a745;
  text-transform: none;
}

#greenToggles.MuiToggleButton-root.Mui-selected:hover{
  color:black;
  font-weight:bold;
  border-color: #fef6f5;
  background:#28a745;
  text-transform: none;
}

#greyToggles.MuiToggleButton-root{
  color:whitesmoke;
  border-color: #fef6f5;
  background: #222;
  text-transform: none;
}

#greyToggles.MuiToggleButton-root.Mui-selected{
  color:black;
  font-weight:bold;
  border-color: #fef6f5;
  background:grey;
  text-transform: none;
}

#greyToggles.MuiToggleButton-root.Mui-selected:hover{
  color:black;
  font-weight:bold;
  border-color: #fef6f5;
  background:grey;
  text-transform: none;
}


.nav.nav-tabs .nav-link.active {
  color: whitesmoke;
  background: #33404a;
  border-color: #E34824;
  border-width: 1%;
}

.nav.nav-tabs .nav-link {
    color: #fef6f5;
    border-color: #fef6f5;
}


span.MuiTypography-caption{
  color:#fef6f5
}

span.MuiButtonBase-root.MuiTableSortLabel-root.Mui-active{
  color:#ff5a3f
}
span.MuiButtonBase-root.MuiTableSortLabel-root:hover{
  color:#ff5a3f
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon{
  color:#ff5a3f
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon{
  color:#ff5a3f
}


tr.MuiTableRow-root.MuiTableRow-footer{
  overflow:auto;
  margin:auto;
}

.carousel.carousel-slider .control-arrow{
  color:red
}

#readMeAccordion > div{
  margin:0;
}

td.MuiTableCell-body{
  vertical-align: top;
}

/* Weekly review CSS */
span.goldP{
  color:#28a745;
  font-weight:500
}
span.deathP{
  color:#dc3545;
  font-weight:500
}
div.textIndentMe{
  text-indent:30px
}

/* Market Leaders slider label color */
label.google-visualization-controls-label{
  color:white;
  text-indent:30px
}

div.MuiFilledInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #ff5a3f;
  pointer-events: none;
}

div.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #ff5a3f;
  pointer-events: none;
}

/* button.MuiButtonBase-root{
  color:'white'
} */

#myLoadingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
}

/* Need to find out what this is for */
/* div.MuiTooltip-popper{padding-top:3%;} */

/* Accordion filter */
div#backtestInput>div>div>input{
  color:#fef6f5;
/* input#standalone-number:focus{color:#90caf9;margin-top:3px}
input#standalone-number:hover{color:#90caf9;margin-top:3px} */
}

label#inputNote-label>input{
  color:#fef6f5;

}

div#userSettings>div>div>div>input{
  color:#fef6f5;
}

div#SponsorInputField>div>div>div>input{
  color:#fef6f5;
}

/* Accordion filter */
/* input#standalone-number{color:#fef6f5;margin-top:3px} */
input#standalone-number:focus{color:#90caf9;margin-top:3px}
input#standalone-number:hover{color:#90caf9;margin-top:3px}

div.MuiAccordion-root{border: 1px solid #fef6f5}

/* Save Filter  */
input#filterNameInput{ color:#fef6f5;}
input#filterNameInput:focus{color:#90caf9;}
input#filterNameInput:hover{color:#90caf9}

textarea#newFilterDesc{ color:#fef6f5;}
textarea#newFilterDesc:focus{color:#90caf9;}
textarea#newFilterDesc:hover{color:#90caf9}

input.form-control{background:#fef6f5}


/* div */
div.lineHeightReduce > button.MuiButtonBase-root{
  line-height:1.1
}

a>u.activeWhiteLink:hover{
  color:#CDCDCD
}

div.inLineBlockChild>div>div.MuiToolbar-root{
  display:inline-block;
  width:'100%'
}

input#tickerInputBar, input#tickerInputSearch{ color:white}

/* td,tr{background:'red' !important} */

/* This is for the the screener tabs */
button.Mui-selected{
  background:#33404a;
  border-color: #E34824;
  border-width: 1%;
}

/* This is for the tooltips in the material table (export, change columns, etc.) */
div.MuiPopover-paper > ul.MuiList-root{
  background:#1d2124;
  color:#fff
}
ul.MuiList-root > li:hover{
  background:#2c3134;
}
.MuiTooltip-tooltip.transparent {
  background-color: transparent !important;
  color: #2f343D!important;
}

/* Google table custom css */
/* Base table styles for a modern, borderless design */
.googTableHeaderRow, .googTableRow {
  font-family: Arial, sans-serif;
  color: #E1E8ED; /* Light grey for text, ensuring readability */
}

.googTableHeaderRow {
  background-color: #1D2124; /* Dark theme color for contrast */
}

.googTableCell, .googTableHeaderCell {
  padding: 8px 16px !important; /* Ample padding for a spacious feel */
  text-align: left; /* Clean, left-aligned text */
}

/* Alternating row colors for readability, without borders */
.googTableRow {
  background-color: #1D2124; /* Matches the table background */
}

.googOddTableRow {
  background-color: #1D2124; /* Slightly darker to subtly distinguish rows */
}

/* Interactive states without borders */
.googHoverTableRow {
  background-color: #343A40; /* Lighter to indicate hover state effectively */
}

.googSelectedTableRow {
  background-color: #FF5A3F; /* Accent color for selection highlight */
  color: white; /* Ensures text is readable against the accent color */
}

/* Header cell styles */
.googTableHeaderCell {
  font-weight: bold; /* Emphasizes header cells */
}

/* Cell styles */
.googTableCell {
  vertical-align: top; /* Aligns content to the top for a tidy look */
  border: 0 !important;
}

/* Darken dashboard liste items on hover */
.nav-link-hover {
  transition: filter 0.3s;
}
.nav-link-hover:hover {
  filter: brightness(0.75); 
}

/* div#screenerTabRow>div>div.MuiTabs-flexContainer{
  background:#1D2124;
  border-radius:4px;
  margin-bottom:0;
} */
td.google-visualization-table-table{
  border: 0
}
@media screen and (max-width: 600px) {
  #dataGridContainer{
    margin:0 !important
  }
}

@media screen {
  .dropdown-menu{
      height:200px;
      overflow-y:auto;
  }
}